<template lang="pug">
  .c-table.c-session-table
    .c-table__head.ed-grid.m-grid-11
      .m-cols-1.center #
      .m-cols-1.center ID
      .m-cols-1.center Reagendado
      .m-cols-1.center Fecha cita
      .m-cols-1.center H. cita
      .m-cols-1.center Institución
      .m-cols-1.center Nombres y Apellidos
      .m-cols-1.center Telefono
      .m-cols-1.center Psicólogo
      .m-cols-1.center Sesión
      .m-cols-1.center Tiempo
    .c-table__body
      Row(
        v-for="(session, key) in sessions"
        :session="session"
        :key="key"
        :itemKey="key"
      )
</template>

<script>
import Row from '@/components/02-molecule/sessionReschedule/row.vue'

export default {
  components: { Row },
  props: {
    sessions: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
