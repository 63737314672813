import Vue from 'vue'

/**
* Formatear el id agregando ceros e iniciales en base a la cantidad de digitos
* @param {number} id
* @param {string} initials
* @return {string}
*/
export const formatID = (id, initials) => {
  if (id.toString().length === 1) return `${initials}00${id}`
  if (id.toString().length === 2) return `${initials}0${id}`
  return `${initials}${id}`
}

/**
 * Recoger fecha y hora.
 * @param {Date} fullDate
 * @returns {object}
 */

// class DateTime {

// }

export const formatDate = (fullDate) => {
  if (fullDate !== null && fullDate !== undefined) {
    // obs: momentjs le resta 5horas a la fecha actual.
    let newDate = Vue.moment(fullDate)
    if (fullDate.includes('Z')) {
      newDate = Vue.moment(fullDate).add(5, 'hours')
    }
    const date = newDate.format().split('T')[0]
    const dayWeekend = newDate.isoWeekday()
    const [year, month, day] = date.split('-')
    const hour = newDate.format().split('T')[1].split('-')[0]
    return {
      date,
      hour,
      day,
      year,
      month,
      dayWeekend
    }
  }
  return {
    date: null,
    hour: null,
    day: null,
    year: null,
    month: null
  }
}

export const getAge = (birthday) => {
  const nowDate = Vue.moment()
  const birthdayFormat = Vue.moment(birthday)
  return nowDate.diff(birthdayFormat, 'years')
}
