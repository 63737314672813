<template lang="pug">
  .c-sessions
    .ed-grid.m-grid-12
      .m-cols-2
        DownloadExcel.e-btn.e-btn--secondary(
          :data="excel.json_data"
          :fields="excel.json_fields"
          :worksheet="excel.worksheet"
          :name="excel.name"
          style="display:flex; align-items:center;padding:0 20px;height: 33px"
        )
          img(
            src="@/assets/images/excel.svg"
            style="margin:0 10px 0 0"
          )
          span Descargar
      .m-cols-2
        .e-btn.e-btn--secondary(
          @click="goToPage('PatientsPage')"
        ) Ver Pacientes
      .m-cols-12
        SessionRescheduleTable(
          :sessions="S_SESSIONS"
        )
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SessionRescheduleTable from '@/components/03-organism/sessionReschedule/table.vue'
import jwtDecode from 'jwt-decode'
import { formatID, formatDate } from '@/utils/globals.js'

export default {
  components: { SessionRescheduleTable },
  data () {
    return {
      excel: {
        json_fields: {
          ID: 'id',
          'Reagendado Paciente': 'reschedule_patient',
          'Reagendado Sesion': 'reschedule_session',
          'Fecha Cita': 'date',
          Institucion: 'institution',
          'Nombre completo del paciente': 'profile_catalog_fullname',
          'Telefono paciente': 'phone',
          Psicologo: 'psychologist',
          'Numero de sesion': 'week',
          Tiempo: 'time_reschedule'
        },
        json_data: [],
        worksheet: 'Sesiones No Reagendadas',
        name: 'Sesiones No Reagendadas.xls'
      }
    }
  },
  async created () {
    try {
      await this.A_GET_SESSIONS({
        to: 'reschedule',
        offset: 0,
        limit: 50,
        institution: this.user.idInstitution
        // institution: 3
      })
      this.excel.json_data = this.downloadExcel(this.S_SESSIONS)
    } catch (error) {
      console.log(error)
    }
  },
  computed: {
    ...mapState({
      S_SESSIONS: state => state.Session.S_SESSIONS
    }),
    user () {
      return jwtDecode(this.$cookies.get('AUTH_TOKEN')).user
    }
  },
  methods: {
    ...mapActions({
      A_GET_SESSIONS: 'Session/A_GET_SESSIONS'
    }),
    goToPage (name) {
      this.$router.push({ name })
    },
    downloadExcel (sessions) {
      return sessions.map(session => {
        const patient = session.Patient
        const Psychologist = session.Psychologist
        const ProfileCatalog = session.ProfileCatalog
        return {
          id: formatID(session.id, 'S'),
          reschedule_patient: patient.reschedule || 0,
          reschedule_session: session.reschedule || 0,
          date: formatDate(session.date).date,
          institution: patient.Institution.name,
          profile_catalog_fullname: ProfileCatalog ? `${ProfileCatalog.firstname} ${ProfileCatalog.lastname}` : '',
          phone: patient.phone,
          psychologist: `${Psychologist.firstname} ${Psychologist.lastname}`,
          week: session.week,
          time_reschedule: this.$moment().diff(session.date, 'hours') + ' hrs'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
