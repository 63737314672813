<template lang="pug">
  .row.ed-grid.m-grid-11
    .m-cols-1.center
      .cell {{ itemKey + 1 }}
    .m-cols-1.center
      .cell {{ sessionID }}
    .m-cols-1.center
      .cell Paciente: {{ patient.reschedule || 0 }} Session : {{ session.reschedule || 0 }}
    .m-cols-1.center
      .cell {{ sessionDate.date }}
    .m-cols-1.center
      .cell {{ sessionDate.hour }}
    .m-cols-1.center
      .cell {{ institution }}
    .m-cols-1.center
      .cell {{ profileCatalog.fullname }}, {{ profileCatalog.age }}
    .m-cols-1.center
      .cell {{ profileCatalog.phone }}
    .m-cols-1.center
      .cell {{ psychologist.fullname }}, {{ psychologist.age }}
    .m-cols-1.center
      .cell ({{ session.week }})
    .m-cols-1.center
      .cell {{ timeReschedule }} hrs.
</template>

<script>
import { formatID, formatDate, getAge } from '@/utils/globals.js'

export default {
  props: {
    session: {
      required: true,
      type: Object
    },
    itemKey: {
      required: true,
      type: Number
    }
  },
  computed: {
    sessionID () {
      return formatID(this.session.id, 'S')
    },
    sessionDate () {
      return formatDate(this.session.date)
    },
    institution () {
      const Patient = this.session.Patient
      const Institution = Patient ? Patient.Institution : null
      return Institution ? Institution.name : null
    },
    patient () {
      const Patient = this.session.Patient
      const info = {
        fullname: '',
        age: '',
        ...Patient
      }
      info.fullname = `${Patient.firstname} ${Patient.lastname}`
      info.age = getAge(Patient.birthday)
      return info
    },
    verifyAgePatient () {
      const ProfileCatalog = this.session.ProfileCatalog
      const Patient = this.session.Patient
      if (Patient.birthday !== '1994-11-12') {
        console.log(Patient.birthday)
        return getAge(Patient.birthday)
      }
      return getAge(ProfileCatalog.birthday)
    },
    profileCatalog () {
      const ProfileCatalog = this.session.ProfileCatalog
      const info = {
        fullname: '',
        age: this.verifyAgePatient,
        ...ProfileCatalog
      }

      info.fullname = ProfileCatalog ? `${ProfileCatalog.firstname} ${ProfileCatalog.lastname}` : ''
      return info
    },
    psychologist () {
      const Psychologist = this.session.Psychologist
      const info = {
        fullname: '',
        age: '',
        ...Psychologist
      }
      info.fullname = `${Psychologist.firstname} ${Psychologist.lastname}`
      info.age = getAge(Psychologist.birthday)
      return info
    },
    timeReschedule () {
      return this.$moment().diff(this.session.date, 'hours')
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
